.font-suma-label-large {
  font-family:
    Barlow Condensed,
    serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.17px;
}

.bg-img-start-goal-saving {
  background-image: url(/images/reelsumaweb10.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 2rem 5px;
  border-color: transparent;
}

.bg-img-start-goal-saving-one {
  background-image: url(/images/reelsumaweb10.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -1rem 5px;
  border-color: transparent;
}

.bg-img-start-goal-saving-two {
  background-image: url(/images/reelsumaweb10.webp);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 10em 3em;
}

.bg-img-start-goal-saving-three {
  background-image: url(/images/reelsumaweb10.webp);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 5em 5em;
}

.bg-img-start-goal-saving-four {
  background-image: url(/images/reelsumaweb10.webp);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 1em 2em;
  margin-bottom: 3em;
}

.bg-img-dollar-footer {
  background-image: url(/images/footer-dollar.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

/** slider carousel dot **/
.swiper-pagination-bullet {
  height: 2px;
  width: 2px;
  background-color: #6b7280;
}
.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  align-items: center;
  height: 14px;
  width: 14px;
  background-color: #2a3651;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 2px #2a3651;
}

/* Custom scrollbar */
.custom-scrollbar-sumaversity::-webkit-scrollbar {
  width: 10px; /* Width of scrollbar */
}

.custom-scrollbar-sumaversity::-webkit-scrollbar-track {
  background-color: #ebedef; /* Color of background the scrollbar */
}

.custom-scrollbar-sumaversity::-webkit-scrollbar-thumb {
  background-color: #2a3651; /* Color the scrollbar */
  border-radius: 5px; /* Ratio of border of scrollbar */
}

.custom-scrollbar-sumaversity::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of scrollbar above move the mouse*/
}

.swiper-pagination-horizontal-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ocupa todo el ancho disponible */
  bottom: 10px; /* Posicionamiento en la parte inferior del swiper */
  left: 0;
}

.swiper-pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet-custom {
  height: 0.6rem;
  width: 0.6rem;
  margin: 0 0 0 0.4rem;
  background-color: #9d9ea0;
  border-radius: 50%;
}

.swiper-pagination-bullet-custom.swiper-pagination-bullet-active-custom {
  align-items: center;
  height: 14px;
  width: 14px;
  background-color: #2A3651;
  border-radius: 1000px;
  border: 3px solid white;
  box-shadow: 0 0 0 2px #2A3651;
}
