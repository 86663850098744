@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: WorkSans;
    src: url('/fonts/work_sans/static/WorkSans-Black.ttf') format('truetype');
    font-weight: 900;
  }

  @font-face {
    font-family: VanguardCF;
    src: url('/fonts/suma_fonts/VanguardCF-Regular.otf') format('opentype');
    font-weight: 400;
  }

  @font-face {
    font-family: VanguardCF;
    src: url('/fonts/suma_fonts/VanguardCF-Medium.otf') format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: VanguardCF;
    src: url('/fonts/suma_fonts/VanguardCF-Bold.otf') format('opentype');
    font-weight: 700;
  }

  @font-face {
    font-family: VanguardCF;
    src: url('/fonts/suma_fonts/VanguardCF-Light.otf') format('opentype');
    font-weight: lighter;
  }
  html {
    -webkit-tap-highlight-color: transparent;
    font-size: 16px;
  }

  body {
    @apply bg-main font-body text-suma-blue-dark underline-offset-1 selection:bg-cta;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-family: VanguardCF, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p {
    @apply font-body text-sm;
  }

  a {
    cursor: pointer;
  }

  input:autofill,
  input:autofill:hover,
  input:autofill:focus,
  input:autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .highlight {
    position: relative;
    z-index: 0;
  }

  .highlight::before {
    content: '';
    position: absolute;
    left: -2%;
    top: -0.1em;
    width: 104%;
    height: 1em;
    @apply bg-highlight;
    @apply bg-opacity-50;
    z-index: -1;
  }
}

@layer utilities {
  /* Fonts */
  .font-heading {
    font-family: VanguardCF, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .outline-title {
    text-shadow:
      1px 1px 0 #2a3651,
      -1px -1px 0 #2a3651,
      1px -1px 0 #2a3651,
      -1px 1px 0 #2a3651,
      1px 1px 0 #2a3651;
  }
}
