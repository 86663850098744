/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type='range'].suma-slider {
  height: 1.2em;
  -webkit-appearance: none;
}

/*progress support*/
input[type='range'].suma-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

/*webkit*/
input[type='range'].suma-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
  background: var(--slider-color);
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(max((1.5em - 1px - 1px) * 0.5, 0px) - 2em * 0.5);
}

input[type='range'].suma-slider::-webkit-slider-runnable-track {
  height: 0.6em;
  border: 1px solid #b2b2b2;
  border-radius: 0em;
  /* background: #efefef; */
  box-shadow: none;
}

input[type='range'].suma-slider:hover::-webkit-slider-runnable-track {
  /* background: #e5e5e5; */
}

input[type='range'].suma-slider:active::-webkit-slider-runnable-track {
  /* background: #f5f5f5; */
}

input[type='range'].suma-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat,
    #efefef;
}

input[type='range'].suma-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat,
    #e5e5e5;
}

input[type='range'].suma-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat,
    #f5f5f5;
}

/*mozilla*/
input[type='range'].suma-slider::-moz-range-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: var(--slider-color);
  border: none;
  box-shadow: 0 0 2px black;
}

input[type='range'].suma-slider::-moz-range-track {
  height: max(calc(1em - 1px - 1px), 0px);
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  /* background: #efefef; */
  box-shadow: none;
}

input[type='range'].suma-slider:hover::-moz-range-track {
  /* background: #e5e5e5; */
}

input[type='range'].suma-slider:active::-moz-range-track {
  /* background: #f5f5f5; */
}

input[type='range'].suma-slider.slider-progress::-moz-range-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat;
  /* #efefef; */
}

input[type='range'].suma-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat;
  /* #e5e5e5; */
}

input[type='range'].suma-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(var(--slider-color), var(--slider-color)) 0 / var(--sx) 100% no-repeat;
  /* #f5f5f5; */
}

/*ms*/
input[type='range'].suma-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type='range'].suma-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type='range'].suma-slider::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: var(--slider-color);
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type='range'].suma-slider::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  /* background: #efefef; */
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type='range'].suma-slider:hover::-ms-track {
  /* background: #e5e5e5; */
}

input[type='range'].suma-slider:active::-ms-track {
  /* background: #f5f5f5; */
}

input[type='range'].suma-slider.slider-progress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px), 0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: var(--slider-color);
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}
