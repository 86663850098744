/* Taken and modified from react-calendar library */
.react-calendar {
  width: 800px;
  max-width: 100%;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  opacity: 0.2;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  opacity: 0.75;
}
.react-calendar__navigation__label {
  @apply font-heading text-xl md:text-4xl font-bold;
}
.react-calendar__month-view__weekdays {
  @apply text-center font-heading text-sm font-medium md:text-xl;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday abbr {
  @apply no-underline;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  width: 100%;
  @apply flex items-center justify-center py-4 text-xs md:px-8 md:py-6 md:text-sm;
  background: none;
  text-align: center;
}
.react-calendar__tile abbr {
  @apply leading-none;
}
.react-calendar__tile:disabled {
  background: transparent;
  opacity: 0.1;
}
.react-calendar__tile--now {
  @apply font-medium;
}
.react-calendar__tile--active {
  @apply relative z-0 text-white;
}
.react-calendar__tile--active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply z-[-1] h-8 w-8 rounded-full bg-suma md:h-12 md:w-12;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
